<template>
  <div privacy-terms-page>
    <div class="user-terms-con">
      <section>
        <h1>개인정보 취급방침</h1>
        <h2>[1조] 회사에서 개인 정보 처리 방침의 의의</h2>
        <ol>
          <li>1항) 회사(이하 ‘빅픽처’ 또는 회사’)는 회사에서 제공하는 모든 서비스의 기획, 운영, 종료까지 정보통신망 이용 촉진 및 정보보호 등에 관한 법률(이하 ‘정보통신망법’), 대한민국 개인 정보보호법, 국내의 모든 개인 정보 보호 법령과 대한민국 행정안전부에서 권고하는 개인 정보 보호조치 가이드를 준수합니다.
            <div class="terms-wide-table terms-table-wrap">
              <table>
                <tbody>
                  <tr>
                    <th>행정안전부 개인 정보보호 권고사항</th>
                    <td>
                      <a href="https://www.privacy.go.kr/per/rel/dev/Guide.do" target="_blank">https://www.privacy.go.kr/per/rel/dev/Guide.do</a>
                    </td>
                  </tr>
                  <tr>
                    <th>정보통신망법 바로가기</th>
                    <td>
                      <a href="http://www.law.go.kr/lsInfoP.do?lsiSeq=167388&efYd=20150421#0000" target="_blank">http://www.law.go.kr/lsInfoP.do?lsiSeq=167388&efYd=20150421#0000</a>
                    </td>
                  </tr>
                  <tr>
                    <th>개인 정보보호법 바로가기</th>
                    <td>
                      <a href="http://www.law.go.kr/lsInfoP.do?lsiSeq=173223&efYd=20150724#0000" target="_blank">http://www.law.go.kr/lsInfoP.do?lsiSeq=173223&efYd=20150724#0000</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </li>
          <li>2항) 개인 정보처리 방침은 시행 일로부터 적용되며, 법령 및 방침에 따른 변경 내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항 시행 7일 전부터 공지사항 또는 회원 이메일을 통하여 고지합니다.
          </li>
        </ol>
        <h2>[2조] 개인 정보의 정의 및 관리 목적</h2>
        <ol>
          <li>1항) 회사에서 정의하는 개인 정보: 회사에서 제공하는 서비스에서 “개인 정보”란 생존하는 개인에 관한 정보로서 당해 정보에 포함되어 있는 성명, 주민등록번호와 같은 당해 개인을 식별할 수 있는 정보를 말합니다.
          </li>
          <li>2항) 회사는 서비스 운영에 있어 목적을 두고 회원의 개인 정보를 수집, 운영해야 하며, 목적이 변경될 경우에는 개인 정보 처리 방침의 의의에서 밝힌 바와 같이 공지사항 또는 회원의 이메일을 통해 고지합니다. 회사가 개인 정보를 수집, 운영하는 목적은 아래 각 호와 같습니다.
            <ul>
              <li>1호) 홈페이지 회원가입 및 관리: 회원 가입 의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 만 14세 미만 아동 개인 정보 수집 시 법정대리인 동의 여부 확인, 각종 고지·통지, 고충처리, 분쟁 조정 고지사항 전달, 본인 의사 확인, 불만 처리, 서비스 이용에 대한 리뷰 작성 권유, 그 밖에 회사가 제공하는 서비스 안내 및 광고성 정보 전송, 게임물 등급 분류에 따른 서비스 이용 자격 식별을 위한 기록 보존 등을 목적으로 개인 정보를 처리합니다.
              </li>
              <li>2호) 재화 또는 서비스 제공: 서비스 제공, 청구서 발송, 콘텐츠 제공, 맞춤 서비스 제공, 본인인증, 연령 인증, 요금 결제·정산, 새로운 서비스와 이벤트 정보 안내 등을 목적으로 개인 정보를 처리합니다.
              </li>
              <li>3호) 마케팅 및 광고에의 활용: 신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여 기회 제공 , 인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인 정보를 처리합니다.
              </li>
              <li>4호) 생년월일 및 성별 : 게임물 등급 분류에 따른 서비스 이용 자격 식별을 목적으로 개인 정보를 처리합니다.</li>
            </ul>
          </li>
          <li>3항) 회사에서 수집하는 개인정보의 항목 및 수집 방법은 아래 각 호와 같습니다.
            <ul>
              <li>1호) 회원 가입 시
                <div class="terms-wide-table terms-table-wrap">
                  <table>
                    <tbody>
                      <tr>
                        <th>목적</th>
                        <td>회원제 서비스 제공, 개인 식별, 중요 필수 내용 고지</td>
                      </tr>
                      <tr>
                        <th>항목</th>
                        <td>아이디, 비밀번호, 이름, 생년월일, 성별, 연락처(이메일, 휴대전화번호), 본인확인 값(CI, DI), 14세 미만 회원의 경우 법정대리인 정보(이름, 생년월일, 인증정보, 이메일)
                        </td>
                      </tr>
                      <tr>
                        <th>보유기간</th>
                        <td>회원 탈퇴시까지 (일반탈퇴 시 7일간 보유)</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </li>
              <li>2호) 서비스 이용 시
                <div class="terms-wide-table terms-table-wrap">
                  <table>
                    <tbody>
                      <tr>
                        <th>목적</th>
                        <td>서비스 개발 및 보상 제공</td>
                      </tr>
                      <tr>
                        <th>항목</th>
                        <td>IP Address, 쿠키, 방문 일시, 서비스 이용 기록, 불량 이용 기록, 모바일 앱 설치 시 Device Token, 단말기 정보(모델명, os 버전, 기기고유번호, 펌웨어 버전등), 통신사 정보, 게임/플랫폼 계정
                        </td>
                      </tr>
                      <tr>
                        <th>보유기간</th>
                        <td>회원 탈퇴시까지 (일반탈퇴 시 7일간 보유)</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </li>
              <li>3호) 본인 확인 시
                <div class="terms-wide-table terms-table-wrap">
                  <table>
                    <tbody>
                      <tr>
                        <th>목적</th>
                        <td>서비스 제공 및 법정대리인의 동의가 필요한 경우, 관계 법령 준수를 위해 본인확인이 필요한 경우</td>
                      </tr>
                      <tr>
                        <th>항목</th>
                        <td>이름, 생년월일, 성별, 내/외국인, 휴대전화번호, 이동 통신사정보(선택), 또는 아이핀 정보(선택), 본인확인 값(CI, DI)</td>
                      </tr>
                      <tr>
                        <th>보유기간</th>
                        <td>회원 탈퇴시까지 (일반탈퇴 시 7일간 보유)</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </li>
              <li>4호) 주문/결제/배송/취소/환불/출금 시
                <div class="terms-wide-table terms-table-wrap">
                  <table>
                    <tbody>
                      <tr>
                        <th>목적</th>
                        <td>주문 상품의 결제 및 환불, 배송/계약사항 및 주문내역 안내 등 고지/부정이용 확인∙방지관리</td>
                      </tr>
                      <tr>
                        <th>항목</th>
                        <td>
                          [결제수단 선택에 따른 수집]<br> 신용카드 : 카드사명, 카드번호, 유효기간 휴대전화 : 휴대전화번호, 통신사명 계좌이체 : 은행명, 계좌번호 무통장입금 : 은행명, 입금자명 현금영수증발급정보 : 휴대전화번호,현금영수증카드정보,주민(사업자)번호 <br>[배송]<br> 수취인정보-성명, 연락처, 주소 <br>[취소/환불]<br> 예금주 성명, 은행명, 계좌번호
                        </td>
                      </tr>
                      <tr>
                        <th>보유기간</th>
                        <td>회원 탈퇴시까지 (일반탈퇴 시 7일간 보유)</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </li>
              <li>5호) 레벨업 코치 지원 시
                <div class="terms-wide-table terms-table-wrap">
                  <table>
                    <tbody>
                      <tr>
                        <th>목적</th>
                        <td>본인확인 및 가입의사 확인, 상담 및 문의 처리, 공지사항 전달 및 불만처리 등 원활한 의사소통 경로 확보</td>
                      </tr>
                      <tr>
                        <th>항목</th>
                        <td>레벨업지지회원가입 정보 + 휴대폰번호, 코치게임 정보(게임,닉네임,등급,포지션,챔피언,소개,디스코드 등)</td>
                      </tr>
                      <tr>
                        <th>보유기간</th>
                        <td>회원 탈퇴시까지 (일반탈퇴 시 7일간 보유)</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </li>
            </ul>
          </li>
          <li>
            4항) 위 2항 각 '호'에서 명시한 목적 외에 것 또는 회원의 기본적 인권 침해의 우려가 있는 민감한 개인 정보는 수집하지 않습니다.
          </li>
        </ol>
        <h2>[3조] 쿠키를 통한 개인 정보 수집</h2>
        <ol>
          <li>1항) 회사는 회원에게 제공하는 서비스를 더 나은 방향으로 발전시키기 위한 목적으로 회원에 대한 정보를 저장하고 찾아내는 ‘쿠키(cookie)’를 사용할 수 있습니다. 그 목적과 운영 기준은 아래 각 '호'와 같습니다.
            <ul>
              <li>1호) 쿠키 활용 목적: 회원의 행동을 분석하여 서비스 개편 등의 척도를 만드는데 활용</li>
              <li>2호) 쿠키 활용 종료: 회원이 브라우저 종료 시 또는 로그아웃 처리가 완료된 경우</li>
            </ul>
          </li>
          <li>2항) 회원은 쿠키에 대한 선택권이 있으며, 모든 쿠키를 다 받아들이거나 쿠키가 설치될 때 통지를 보내도록 하거나 아니면 모든 쿠키를 거부할 수 있는 선택권을 가질 수 있습니다. 다만, 쿠키 설치를 거부하셨을 경우 정상적인 사이트 이용이 어려울 수 있습니다.
          </li>
          <li>3항) 쿠키 수집 거부 방법
            <div class="terms-wide-table terms-table-wrap">
              <table>
                <tbody>
                  <tr>
                    <th>인터넷 익스플로러</th>
                    <td>웹 브라우저 상단의 도구 메뉴 > 인터넷 옵션 > 개인정보 > 설정</td>
                  </tr>
                  <tr>
                    <th>CHROME</th>
                    <td>웹 브라우저 우측의 설정 메뉴 > 화면 하단의 고급 설정 표시 > 개인정보의 콘텐츠 설정 버튼 > 쿠키</td>
                  </tr>
                  <tr>
                    <th>Safari</th>
                    <td>Safari > 환경설정> 개인 정보 보호</td>
                  </tr>
                  <tr>
                    <th>Firefox</th>
                    <td>메뉴 > 설정환경 설정 > 개인 정보 및 보안 > 쿠키와 사이트 데이타 섹션</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </li>
        </ol>
        <h2>[4조] 제3자 개인 정보 제공 및 활용 허락</h2>
        <ol>
          <li>1항) 회사는 정보주체의 동의, 법률의 특별한 규정 등 개인 정보 보호법 제17조 및 제18조에 해당하는 경우 개인 정보를 제3자에게 제공할 수 있습니다.</li>
          <li>2항) 회사는 회원의 개인 정보를 「개인 정보의 정의 및 관리 목적」에서 고지한 범위 내에서 사용하며, 동 범위를 초과하여 이용하거나 타인 또는 타기업, 기관에 절대 제공하지 않습니다.
          </li>
          <li>3항) 회사는 아래 각 '호'의 목적으로 회사와 이해관계가 있는 대상에게 개인 정보를 제공 및 활용을 허락할 수 있습니다.
            <ul>
              <li>1호) 제휴 관계에서 개인 정보의 활용 및 제공: e-Sports 아레나 운영과 참가에 관련한 이용계약의 이행을 위하여 회원의 개인 정보를 제휴사에게 제공하거나 제휴사와 공유할 수 있습니다.
              </li>
              <li>2호) 위탁처리: 원활한 업무 처리를 위해 회원의 개인 정보를 위탁 처리할 경우 반드시 사전에 위탁처리 업체명과 위탁 처리되는 개인 정보의 범위, 업무 위탁 목적, 위탁처리되는 과정, 위탁관계 유지 기간 등에 대해 회원에게 상세하게 고지합니다.
              </li>
              <li>3호) 매각, 인수합병 등: 서비스 제공자의 권리와 의무가 완전 승계 이전되는 경우 반드시 사전에 정당한 사유와 절차에 대해 상세하게 고지할 것이며 회원의 개인 정보에 대한 동의 철회의 선택권을 부여합니다.
              </li>
            </ul>
          </li>
          <li>4항) 회사와의 이해관계인 또는 단체에게 개인 정보를 제공 및 활용 허락을 시행하기 전에 회원에게 고지 및 동의 방법은 온라인 홈페이지 초기화면의 공지사항을 통해 최소 10일 이전부터 고지함과 동시에 이메일 등을 이용하여 1회 이상 개별적으로 고지합니다.
          </li>
          <li>5항) 회사가 제3자에게 개인 정보를 제공 및 활용 허락 행위를 하는 데 있어 회원에게 고지 및 동의 의무가 없는 경우는 아래 각 '호'와 같습니다.
            <ul>
              <li>1호) 관계법령에 의하여 수사상의 목적으로 관계 기관으로부터의 요구가 있을 경우</li>
              <li>2호) 통계 작성, 학술연구나 시장조사를 위하여 특정 개인을 식별할 수 없는 형태로 광고주 협력사나 연구 단체 등에 제공하는 경우</li>
              <li>3호) 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우</li>
            </ul>
          </li>
        </ol>
        <h2>[5조] 개인 정보의 위탁</h2>
        <ol>
          <li>1항) 회사는 원활한 개인 정보 업무처리를 위하여 개인 정보 처리 업무를 회사와 이해관계에 있는 개인 또는 단체에 위탁 행위를 요청할 수 있으며, 수탁자가 결정된 경우 회사는 회사 또는 서비스 웹사이트 내 공지사항이나 회원에게 이메일을 통해 통지합니다.
          </li>
          <li>2항) 회사는 위탁계약 체결 시 개인 정보 보호법 제25조에 따라 위탁업무 수행 목적 외의 개인 정보 처리 금지, 기술적․관리적 보호 조치, 재위탁 제한, 수탁자에 대한 관리․감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인 정보를 안전하게 처리하는지를 감독합니다.
          </li>
          <li>3항) 회사는 위탁업무의 내용이나 수탁자가 변경될 경우 회사 또는 서비스 웹사이트 내 공지사항이나 회원에게 이메일을 통해 통지합니다.</li>
        </ol>

        <h2>[6조] 개인 정보의 열림 및 정정</h2>
        <ol>
          <li>1항) 회원은 언제든지 등록되어 있는 본인의 개인 정보를 열람하거나 정정할 수 있습니다.</li>
          <li>2항) 회원이 개인 정보 열람 및 정정을 하고자 할 경우에는 '개인 정보관리'를 클릭하여 직접 열람하여 정정하거나, 개인 정보관리 책임자 및 담당자에게 서면, 전화 또는 E-mail로 연락하여 개인 정보 정정을 요청할 수 있습니다.
          </li>
          <li>3항) 회원이 개인 정보의 오류에 대한 정정을 요구한 시점 이후로, 회사가 정정을 완료하기 전까지의 회사는 해당 회원의 개인 정보를 활용 또는 제3자에게 배포할 수 없습니다.</li>
          <li>4항) 회사가 잘못된 개인 정보를 제3자에게 이미 제공한 경우 정정 처리결과를 제3자에게 지체 없이 통지하여 정정하도록 조치합니다.</li>
        </ol>

        <h2>[7조] 개인 정보 수집, 이용, 제공에 대한 동의 철회 (회원 탈퇴)</h2>
        <ol>
          <li>1항) 회원가입 등을 통해 개인 정보의 수집, 이용, 제공에 대해 회원이 동의한 내용을 회원은 언제든지 철회할 수 있습니다. 동의 철회 의사를 밝힌 회원은 회사는 그 회원의 서비스 탈퇴로 간주합니다.
          </li>
          <li>2항) 동의 철회는 회사가 운영하는 서비스 내 회원 탈퇴를 클릭하거나 개인 정보관리 책임자에게 팩스, 우편, 고객센터, 전화 등으로 연락하여 동의 철회 및 회원 탈퇴 신청을 할 수 있으며, 신청 즉시 회사는 개인 정보의 삭제, 파기 조치를 취합니다.
          </li>
          <li>3항) 회사는 회원의 동의 철회 요청을 근거로 개인 정보 파기를 완료한 이후, 동의 철회를 요청한 회원에게 그 결과를 회원 이메일을 통해 안내합니다.</li>
          <li>4항) 회사는 회원이 개인 정보 수집에 대한 동의 철회(회원 탈퇴)를 쉽게 할 수 있도록 하는 의무를 갖습니다.</li>
        </ol>
        <h2>[8조] 수집하는 개인 정보의 보유 및 이용기간</h2>
        <ol>
          <li>1항) 회사는 회원의 개인 정보를 회원이 서비스를 이용하는 시점부터 종료하는 기간까지만 보유, 활용, 제3자 제공 행위를 할 수 있습니다.</li>
          <li>2항) 회사가 보유 및 활용하였던 회원의 개인 정보 중 아래 각 '호'에 해당이 된다면 즉시 파기 대상으로 간주합니다.
            <ul>
              <li>1호) 회원이 동의 철회(회원 탈퇴)를 요청하거나 회원의 마지막 로그인 일시를 기준으로 1년이 초과되어 회원의 계정이 휴면계정으로 전환된 경우</li>
              <li>2호) 회원이 제공한 개인 정보의 수집 및 이용에 대한 동의를 철회 의사를 밝힌 경우</li>
              <li>3호) 수집 및 이용목적이 달성되거나 보유 및 그 기간이 종료된 경우</li>
            </ul>
          </li>
          <li>3항) 회사는 위 (2항)에서 기술한 파기 대상 개인 정보를 아래의 각 '호'에서 설명한 방법으로 파기절차를 진행합니다. 파기절차가 완료되면 회사는 그 결과를 회원에게 이메일을 통하여 통지합니다.
            <ul>
              <li>1호) 종이에 출력된 개인 정보 : 분쇄기로 분쇄하거나 소각</li>
              <li>2호) 전자적 파일 형태로 저장된 개인 정보 : 기록을 재사용할 수 없는 기술적 방법을 사용하여 삭제</li>
            </ul>
          </li>
          <li>4항) 회사는 위 (2항)에서 기술한 파기 대상이 되는 개인 정보를 상법, 전자상거래 소비자보호에 관한 법률 등 관련 법령의 규정에 의하여 일정 기간 개인 정보를 보유하여야 할 필요가 있다고 판단된 경우 일정 기간 보유할 수 있습니다. (법률 근거: 서비스 이용 관련 개인 정보(로그인 기록) 보존 근거: 통신비밀 보호법 보존 기간 3개월)
          </li>
          <li>5항) 회원가입 정보의 경우, 회원을 탈퇴, 제명된 경우 또는 회원의 계정이 휴면계정으로 전환된 경우 삭제 처리되며, 회사는 이 결과를 회원에게 이메일을 통하여 통지합니다.</li>
          <li>6항) 회사는 회원의 동의를 받아 보유하고 있는 거래 정보 등을 회원이 열람을 요구하는 경우 지체 없이 그 열람 확인할 수 있도록 조치합니다.</li>
          <li>7항) 회사는 법령에 따른 개인 정보 보유·이용기간 또는 정보주체로부터 개인 정보를 수집 시에 동의 받은 개인 정보를 회원이 이용기간 내에 보유 및 활용합니다. 회사의 처리 개인</li>
          <li>8항) 회사는 회사에서 운영하는 '홈페이지 회원가입 및 관리'에 관한 개인 정보 규정을 '서비스 및 상품의 원활한 서비스 제공'을 근거로 운영하고 있습니다. 각 항목 및 보유 기간은 아래 각 '호'와 같습니다.
            <ul>
              <li>1호) 계약 또는 청약철외 등에 관한 기록 : 5년 (근거 법률 : 전자상거래 등에서의 소비자 보호에 관한 법률)</li>
              <li>2호) 대금 결제 및 재화 등의 공급에 관한 기록 : 5년 (근거 법률 : 전자상거래 등에서의 소비자 보호에 관한 법률)</li>
              <li>3호) 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 (근거 법률 : 전자상거래 등에서의 소비자 보호에 관한 법률)</li>
              <li>4호) 표시 또는 광고에 관한 기록 : 6개월 (근거 법률 : 전자상거래 등에서의 소비자 보호에 관한 법률)</li>
              <li>5호) 웹 사이트 방문 기록 : 3개월 (근거 법률 : 통신비밀보호법)</li>
              <li>6호) 보유기간을 이용자에게 미리 고지하거나 개별적으로 이용자의 동의를 받은 경우 : 고지하거나 개별 동의한 기간</li>
            </ul>
          </li>
        </ol>
        <h2>[9조] 개인 정보 주체와 법정대리인의 권리·의무 및 행사방법</h2>
        <ol>
          <li>1항) 회원은 개인 정보주체로서 회사 또는 회사가 운영하는 상품이나 서비스를 대상으로 다음과 각 호와 같은 권리를 행사할 수 있습니다.
            <ul>
              <li>1호) 회원은 회사 또는 회사에서 운영하는 상품이나 서비스를 대상으로 언제든지 개인 정보 열람, 정정, 삭제, 처리정지 및 요구 등의 권리를 행사할 수 있습니다.</li>
              <li>2호) 제1항에 따른 권리 행사는 회사에 대해 개인 정보 보호법 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 할 수 있으며 회사는 이에 대해 지체 없이 조치합니다.
              </li>
              <li>3호) 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인 정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
              </li>
              <li>4호) 개인 정보 열람 및 처리정지 요구는 개인 정보보호법 제35조 제5항, 제37조 제2항에 의하여 정보주체의 권리가 제한될 수 있습니다.</li>
              <li>5호) 개인 정보의 정정 및 삭제 요구는 다른 법령에서 그 개인 정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.</li>
              <li>6호) 회사는(는) 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.</li>
            </ul>
          </li>
        </ol>
        <h2>[10조] 회사의 개인 정보 파기의 의무</h2>
        <ol>
          <li>1항) 회사가 개인 정보 파기할 때에는 원칙적으로 개인 정보 처리 목적이 달성된 경우에는 지체 없이 해당 개인 정보를 파기합니다. 파기의 절차, 기한 및 방법은 다음 각 호에서 설명합니다.
            <ul>
              <li>1호) 파기절차: 회원이 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련 법령에 따라 일정 기간 저장된 후 활용되며 개인 정보 처리 목적이 달성된 경우 그 목적이 다하였다고 판단하고 개인 정보를 즉시 파기합니다. 이때, DB로 옮겨진 개인 정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.
              </li>
              <li>2호) 파기 기한: 회원의 개인 정보는 개인 정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터, 개인 정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인 정보가 불필요하게 되었을 때에는 개인 정보의 처리가 불필요한 것으로 인정되는 날로부터 지체없이 그 개인 정보를 파기합니다.
              </li>
              <li>3호) 파기방법: 전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용하며, 종이에 출력된 개인 정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.</li>
            </ul>
          </li>
        </ol>
        <h2>[11조] 개인 정보보호를 위한 기술 및 관리적 대책의 의무</h2>
        <ol>
          <li>1항) 회사는 개인 정보의 안전성 확보 조치 개인 정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적, 관리적, 물리적 조치를 취해야 하는 의무가 있습니다. 개인 정보 보호의 정의와 의무는 아래 각 '호'에서 설명합니다.
            <ul>
              <li>1호) 회사는 회원의 개인 정보를 취급함에 있어 개인 정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적 대책을 강구합니다.</li>
              <li>2호) 회원의 개인 정보는 비밀번호에 의해 보호되며 파일 및 전송 데이터를 암호화하거나 파일 잠금 기능(Lock)을 사용하여 중요한 데이터는 별도의 보안 기능을 통해 보호합니다.
              </li>
              <li>3호) 회사는 백신 프로그램을 이용하여 컴퓨터바이러스에 의한 피해를 방지하기 위한 조치를 취합니다. 백신 프로그램은 주기적으로 업데이트되며 갑작스러운 바이러스가 출현할 경우 백신이 나오는 즉시 이를 제공함으로써 개인 정보가 침해되는 것을 방지합니다.
              </li>
              <li>4호) 회사는 암호 알고리즘을 이용하여 네트워크 상의 개인 정보를 안전하게 전송할 수 있는 보안장치(SSL)를 채택하여 운영합니다.</li>
              <li>5호) 해킹 등 외부 침입에 대비하여 각 서버마다 침입차단시스템 및 취약점 분석 시스템 등을 이용하여 보안 대책을 준비합니다.</li>
            </ul>
          </li>
          <li>2항) 회사는 회원의 개인 정보보호 방법 중 관리적 대책 수립 의무를 회원의 개인 정보에 대한 접근 권한을 최소한의 인원으로 제한하는 것으로 수행합니다. 접근 권한을 갖는 인원은 아래 각 '호'에서 설명합니다.
            <ul>
              <li>1호) 회사에 소속되어 회원을 직접 또는 간접 상대로 하여 마케팅 업무를 수행하는 자</li>
              <li>2호) 회사에 소속되어 고객의 불만 및 이용문의 처리 업무를 수행하는 자</li>
              <li>3호) 회사에 소속되어 개인 정보관리 책임자 및 담당자 등 개인 정보관리 업무를 수행하는 자</li>
              <li>4호) 회사에 소속되어 기타 업무상 개인 정보의 취급이 불가피한 자</li>
            </ul>
          </li>
          <li>3항) 회사에 입사하는 전 직원을 대상으로 보안서약서를 작성하여 인재에 의한 정보 유출을 사전에 방지하고 개인 정보취급방침에 대한 이행사항 및 직원의 준수 여부를 감사하기 위한 내부 절차를 마련합니다.
          </li>
          <li>4항) 개인 정보 관련 취급자의 업무 인수인계는 보안이 유지된 상태에서 철저하게 이뤄지며, 입사 및 퇴사 후 개인 정보 사고에 대한 책임을 명확하게 합니다.</li>
          <li>5항) 회사는 예상 범위 외 발생할 수 있는 개인 정보에 관한 사고를 미연에 방지하고자 분기별 최소 1회, 아래 각 호에서 기술한 보안 대책 방안을 자체적으로 점검함으로써 회원의 개인 정보 보호를 위해 노력합니다.
            <ul>
              <li>1호) 개인 정보 취급 직원의 최소화 및 교육: 개인 정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화하여 개인 정보를 관리하는 대책을 시행하는지 점검합니다.</li>
              <li>2호) 내부관리 계획의 수립 및 시행: 개인 정보의 안전한 처리를 위하여 상하 직급 구분 없이 모든 구성원이 내부관리 계획을 점검합니다.</li>
              <li>3호) 해킹 등에 대비한 기술적 대책: 회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인 정보 유출 및 훼손을 막기 위하여 보안 프로그램을 정상적으로 설치되었는지 주기적 갱신·점검하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단이 원활하게 작동되는지 점검합니다.
              </li>
              <li>4호) 개인 정보의 암호화 점검: 회원의 개인 정보 중 비밀번호는 암호화되어 저장 및 관리되고 있는지 점검합니다 또, 중요한 데이터는 파일 및 전송 데이터를 암호화하거나 파일 잠금 기능을 사용하는 등의 별도 보안 기능이 설치 그리고, 정상 작동되는지 점검합니다.
              </li>
              <li>5호) 접속기록의 보관 및 위변조 방지: 개인 정보처리시스템에 접속한 기록을 최소 6개월 이상 보관, 관리하며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안 기능 정상 작동되는지 점검합니다.
              </li>
              <li>6호) 개인 정보에 대한 접근 제한: 개인 정보를 처리하는 데이터베이스 시스템에 대한 접근 권한의 부여, 변경, 말소를 통하여 개인 정보에 대한 접근통제를 위하여 필요한 조치를 하고 있는지 점검하고, 침입차단시스템을 이용하여 외부로부터의 무단 접근이 통제되는지 점검합니다.
              </li>
              <li>7호) 문서보안을 위한 잠금장치 사용: 개인 정보가 포함된 서류, 보조 저장매체 등이 잠금장치가 있는 안전한 장소에 보관되고 있는지 점검합니다.</li>
              <li>8호) 비인가자에 대한 출입 통제: 개인 정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하는지 점검합니다.</li>
            </ul>
          </li>
          <li>6항) 그 외 내부 관리자의 실수나 기술 관리 상의 사고로 인해 개인 정보의 상실, 유출, 변조, 훼손이 유발될 경우 회사는 즉각 회원에게 그 사실을 알리고 적절한 대책과 보상을 강구할 의무를 갖습니다.
          </li>
          <li>7항) 회사는 회원 개인의 실수나 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해 책임을 지지 않습니다. 회원 개개인이 본인의 개인 정보를 보호하기 위해서 자신의 ID와 비밀번호를 적절하게 관리하고 여기에 대한 책임을 갖습니다.
          </li>
        </ol>
        <h2>[12조] 개인 정보의 위탁처리</h2>
        <ol>
          <li>1항) 회사는 회사가 제공하는 상품 및 서비스의 품질 향상을 위하여 회원의 개인 정보를 수집, 취급, 관리 등을 목적으로 외부에 위탁하여 처리할 수 있습니다.</li>
          <li>2항) 개인 정보의 처리를 위탁하는 경우에는 위탁 기관 및 그 사실을 홈페이지를 통해 미리 회원에게 고지합니다. 다만, 재화 또는 서비스를 홍보하거나 판매를 권유하는 업무를 위탁하는 경우에는 회원 이메일을 통해 개별적으로 통지합니다.
          </li>
          <li>3항) 회사가 과실 없이 서면, 전자우편 등의 방법으로 위탁하는 업무의 내용과 수탁자를 회원에게 알릴 수 없는 경우에 해당사항을 홈페이지에 30일 이상 게시합니다.</li>
          <li>4항) 개인 정보의 처리를 위탁하는 경우에는 위탁계약 등을 통하여 서비스 제공자의 개인 정보보호 관련 지시 엄수와 개인 정보에 관한 비밀유지 의무를 갖습니다.</li>
          <li>5항) 제3자 제공의 금지 및 사고 시의 책임 부담, 위탁기간, 처리 종료 후의 개인 정보의 반환 또는 파기 등을 명확히 규정하고 당해 계약 내용을 서면 또는 전자적으로 보관합니다.</li>
          <li>6항) 회사의 개인 정보 위탁처리 기관 및 위탁업무 내용은 위탁이 이루어지기 30일 전 회사 홈페이지를 통해 고지합니다.</li>
          <li>7항) 개인정보 위탁 업체
            <div class="terms-table-wrap terms-wide-table">
              <table>
                <tbody>
                  <tr>
                    <th>업체명</th>
                    <td>다날</td>
                  </tr>
                  <tr>
                    <th>위탁 목적</th>
                    <td>서비스 이용에 따른 본인확인</td>
                  </tr>
                  <tr>
                    <th>개인 정보 이용기간</th>
                    <td>회원탈퇴 시 혹은 위탁 계약 종료 시 까지</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </li>
        </ol>
        <h2>[13조] 개인 정보보호를 위한 회원의 의무</h2>
        <ol>
          <li>1항) 회원은 개인 정보를 최신의 상태로 정확하게 입력하여 불의의 사고를 예방해야 하는 의무를 갖습니다. 회원이 입력한 부정확한 정보로 인해 발생하는 사고의 책임은 회원 자신에게 있으며 타인 정보의 도용 등 허위정보를 입력할 경우 회원자격이 상실될 수 있습니다.
          </li>
          <li>2항) 회원은 개인 정보를 보호받을 권리와 함께 스스로를 보호하고 타인의 정보를 침해하지 않을 의무가 있습니다. 비밀번호를 포함한 회원의 개인 정보가 유출되지 않도록 조심해야 하며 게시물을 포함한 타인의 개인 정보를 훼손해서는 안 됩니다.
          </li>
          <li>3항) 책임을 다하지 못하고 타인의 정보 및 존엄성을 훼손할 시에는 '정보통신망 이용 촉진 및 정보보호 등에 관한 법률', '개인 정보보호법' 등에 의해 처벌받을 수 있습니다.</li>
        </ol>
        <h2>[14조] 개인 정보처리 방침에 관한 의견수렴</h2>
        <ol>
          <li>1항) 회원은 회사의 개인 정보처리 방침에 관하여 언제든지 서면 또는 유선으로 의견을 낼 수 있으며, 회사는 그 의견이 서비스의 목적과 전체 회원에게 합리적이고 타당하게 적용될 수 있는 사안이라고 판단된다면, 추후 개인 정보처리 방침에 반영할 수 있습니다.
          </li>
        </ol>
        <h2>[15조] 아동의 개인 정보보호</h2>
        <ol>
          <li>1항) 만 14세 미만 아동의 계정 등록은 별도의 양식을 통해 이루어지고 있으며 개인 정보 수집 시 법정대리인의 동의를 구하고 있으며, 이 경우 법정 대리인은 아래 '호'에서 설명한 사항을 제출해야 합니다.
            <ul>
              <li>1호) 법정대리인의 성명, 생년월일, 휴대폰 번호 또는 e-mail, 주민등록번호</li>
            </ul>
          </li>
          <li>2항) 법정대리인은 언제든지 아동의 개인 정보에 대한 열람 및 정정 및 삭제를 요청할 수 있으며, 이러한 요청이 있는 경우 회사는 지체 없이 필요한 조치를 취합니다.</li>
          <li>3항) 회사는 법정대리인의 동의 없이 개인 정보를 제3자에게 제공하거나 공유하지 않으며, 회원 및 법정대리인이 개인 정보의 오류의 정정을 요구하는 경우 그 오류를 정정할 때까지 해당 개인 정보의 이용 및 제공을 금지합니다.
          </li>
          <li>4항) 회사는 법정대리인의 요청에 의해 해지 또는 삭제된 개인 정보는 앞서 명시한 조항에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없습니다.</li>
        </ol>
        <h2>[16조] 고지의 의무</h2>
        <ol>
          <li>1항) 회사는 현 개인 정보취급방침이 정부의 정책 또는 보안 기술의 변경에 따라 내용의 추가, 삭제 및 수정이 있을 시에 개정 최소 10일 전(단, 중요한 내용 변경의 경우 30일 전)부터 홈페이지의 ‘공지’란을 통해 고지할 의무를 갖습니다.
          </li>
        </ol>
        <h2>[안내 1] 개인 정보관리 책임자 및 담당자 안내</h2>
        <ol>
          <li>1. 회사는 개인 정보 처리에 관한 업무를 총괄해서 책임지고, 개인 정보 처리와 관련한 회원의 불만 처리 및 피해 구제 등을 위하여 아래와 같이 개인 정보 보호 책임자를 지정하여 회원의 개인 정보를 관리하고 있습니다.
          </li>
          <li>2. 회원님께서는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인 정보 보호 관련 문의, 불만 처리, 피해 구제 등에 관한 사항을 개인 정보 보호 책임자 및 담당 부서로 문의하실 수 있습니다.
          </li>
          <li>3. 회사는 회원님의 문의에 대해 영업일 이내 지체 없이 답변 및 처리할 의무를 갖고 있습니다.
            <ul>
              <li class="terms-table-wrap terms-wide-table">
                <p>개인 정보 보호 책임자</p>
                <table>
                  <tbody>
                    <tr>
                      <th>성 명</th>
                      <td>김병규</td>
                    </tr>
                    <tr>
                      <th>직 책</th>
                      <td>개발본부장 (개인정보관리 책임자)</td>
                    </tr>
                    <tr>
                      <th>직 급</th>
                      <td>본부장</td>
                    </tr>
                    <tr>
                      <th>연락처</th>
                      <td>+82 2-3144-8001</td>
                    </tr>
                    <tr>
                      <th>이메일</th>
                      <td><a href="mailto:privacy@bigpi.co">privacy@bigpi.co</a></td>
                    </tr>
                  </tbody>
                </table>
              </li>
              <li class="terms-table-wrap terms-wide-table">
                <p>개인 정보 보호 담당 부서</p>
                <table>
                  <tbody>
                    <tr>
                      <th>부서명</th>
                      <td>개발본부</td>
                    </tr>
                    <tr>
                      <th>부서장</th>
                      <td>김병규</td>
                    </tr>
                    <tr>
                      <th>연락처</th>
                      <td>+82 2-3144-8001</td>
                    </tr>
                    <tr>
                      <th>이메일</th>
                      <td><a href="mailto:privacy@bigpi.co">privacy@bigpi.co</a></td>
                    </tr>
                  </tbody>
                </table>
              </li>
              <li class="terms-table-wrap terms-wide-table">
                <p>서비스 제공 회사 개요</p>
                <table>
                  <tbody>
                    <tr>
                      <th>대표자명</th>
                      <td>송광준</td>
                    </tr>
                    <tr>
                      <th>사업자명</th>
                      <td>빅픽처인터렉티브</td>
                    </tr>
                    <tr>
                      <th>사업자 번호</th>
                      <td>286-87-00056</td>
                    </tr>
                    <tr>
                      <th>전자우편</th>
                      <td>help@bigpi.co</td>
                    </tr>
                    <tr>
                      <th>전화번호</th>
                      <td>+82 2-3144-8001</td>
                    </tr>
                    <tr>
                      <th>주 소</th>
                      <td>서울시 금천구 두산로 9길 31 빅픽처인터렉티브</td>
                    </tr>
                  </tbody>
                </table>
              </li>
            </ul>
          </li>
        </ol>
        <h2>[안내2] 개인 정보 열람 청구</h2>
        <ol>
          <li>1. 회원님은 개인 정보 보호법 제35조에 따른 개인 정보의 열람 청구권으로 아래 담당 부서에 개인 정보 열람 청구를 요청할 수 있습니다.
            <ul>
              <li class="terms-table-wrap terms-wide-table">
                <p>개인 정보 열람 청구 접수 및 처리 부서</p>
                <table>
                  <tbody>
                    <tr>
                      <th>부서명</th>
                      <td>개발본부</td>
                    </tr>
                    <tr>
                      <th>부서장</th>
                      <td>김병규</td>
                    </tr>
                    <tr>
                      <th>연락처</th>
                      <td>+82 2-3144-8001</td>
                    </tr>
                    <tr>
                      <th>이메일</th>
                      <td><a href="mailto:privacy@bigpi.co">privacy@bigpi.co</a></td>
                    </tr>
                  </tbody>
                </table>
              </li>
            </ul>
          </li>
          <li>2. 회원님께서는 열람 청구 접수 및 처리부서를 통하는 것 외에도 행정안전부의 ‘개인 정보보호 종합지원 포털’ 웹사이트(www.privacy.go.kr)를 통하여서도 개인 정보 열람 청구를 할 수 있습니다.
            <ul>
              <li class="terms-table-wrap terms-wide-table">
                <p>개인 정보 열람 청구 방법</p>
                <table>
                  <tbody>
                    <tr>
                      <th>방법</th>
                      <td>행정안전부 개인 정보보호 종합지원 포털 → 개인 정보 민원 → 개인 정보 열람 등 요구 (본인확인을 위하여 아이핀(I-PIN)이 있어야 함)</td>
                    </tr>
                  </tbody>
                </table>
              </li>
            </ul>
          </li>
        </ol>
        <h2>[안내3] 정보주체의 권익 침해에 대한 구제방법</h2>
        <ol>
          <li>1. 아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인 정보 불만 처리, 피해 구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다.
            <ul>
              <li class="terms-table-wrap terms-wide-table">
                <p>개인 정보 침해신고센터 (한국 인터넷진흥원 운영)</p>
                <table>
                  <tbody>
                    <tr>
                      <th>소관 업무</th>
                      <td>개인 정보 침해 사실 신고, 상담 신청</td>
                    </tr>
                    <tr>
                      <th>홈페이지</th>
                      <td><a href="http://privacy.kisa.or.kr" target="_blank">privacy.kisa.or.kr</a></td>
                    </tr>
                    <tr>
                      <th>전화</th>
                      <td>(국번 없이) 118</td>
                    </tr>
                    <tr>
                      <th>주소</th>
                      <td>(58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층 개인 정보 침해신고센터</td>
                    </tr>
                  </tbody>
                </table>
              </li>
              <li class="terms-table-wrap terms-wide-table">
                <p>개인 정보 분쟁조정위원회</p>
                <table>
                  <tbody>
                    <tr>
                      <th>소관업무</th>
                      <td>개인 정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)</td>
                    </tr>
                    <tr>
                      <th>홈페이지</th>
                      <td><a href="http://www.kopico.go.kr" target="_blank">www.kopico.go.kr</a></td>
                    </tr>
                    <tr>
                      <th>전화</th>
                      <td>(국번없이) 1833-6972</td>
                    </tr>
                    <tr>
                      <th>주소</th>
                      <td>(03171)서울특별시 종로구 세종대로 209 정부서울청사 4층</td>
                    </tr>
                  </tbody>
                </table>
              </li>
              <li class="terms-table-wrap terms-wide-table">
                <p>개인 정보 분쟁조정위원회</p>
                <table>
                  <tbody>
                    <tr>
                      <th>대검찰청 사이버범죄수사단</th>
                      <td>02-3480-3573 (<a href="http://www.spo.go.kr" target="_blank">www.spo.go.kr</a>)</td>
                    </tr>
                    <tr>
                      <th>경찰청 사이버안전국</th>
                      <td>182 (<a href="http://cyberbureau.police.go.kr"
                        target="_blank">http://cyberbureau.police.go.kr</a>)
                      </td>
                    </tr>
                  </tbody>
                </table>
              </li>
            </ul>
          </li>
        </ol>
        <h2>부칙</h2>
        <p>본 약관은 2021년 4월 7일부터 시행됩니다.</p>
        <p>기존 시행된 방침은 본 약관으로 갈음합니다.</p>
      </section>
      <img class="user-terms__under-logo" src="/img/header-nav/logo-fallback.png" alt="LVUP_logo">
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyTermsPage',
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[privacy-terms-page] {
  padding: 90px 30px 120px;
  background-color: #ebebf0;
  .user-terms-con {
    max-width: 1280px;
    padding: 40px 40px 80px;
    border-radius: 30px;
    background-color: #fff;
    margin: 0 auto;
    color: rgba(0, 0, 0, 0.5);
    h1 {
      font-size: 20px;
      padding-bottom: 16px;
      margin-bottom: 16px;
      border-bottom: 1px solid #bcbcbc;
      color: #000;
    }

    h2 {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 16px;
      color: #000;
      margin-top: 60px;
      &:first-of-type {
        margin-top: 0;
      }
    }

    p, li {
      letter-spacing: -0.025em;
      margin-bottom: 16px;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.6);
      p, li {
        color: rgba(0, 0, 0, 0.4);
      }
      &:last-child {
        margin-bottom: 0;
      }
    }

    li {
      p {
        margin-top: 15px;
      }
    }

    p + ol {
      padding-left: 10px;
    }

    ol {
      ul {
        margin-top: 15px;
        padding-left: 16px;
      }
    }

    a {
      color: #979797;
      word-break: break-all;
    }

    .terms-wide-table {
      th {
        width: 300px;
      }
    }

    .terms-table-wrap {
      th {
        background-color: #ebebf0;
      }
      ul {
        margin-top: 0;
      }
    }

    table {
      margin-top: 12px;
      border: 1px solid #d5d5de;
      border-radius: 12px;
    }
    th,
    td {
      padding: 16px;
      text-align: left;
      li:last-child {
        margin-bottom: 0;
      }
    }
  }
  .terms-wide-table {
    tr {
      th {
        border-bottom: 1px solid #d5d5de;
        border-right: 1px solid #d5d5de;
        color: rgba(0, 0, 0, 0.5);
      }
      td {
        border-bottom: 1px solid #d5d5de;
        color: rgba(0, 0, 0, 0.5);
      }
      &:last-child {
        th {
          border-bottom: 0;
        }
        td {
          border-bottom: 0;
        }
      }
    }
  }
  .user-terms__under-logo { .block; .w(68); .mt(64); .mh-c; }
  @media all and (min-width: 0) and (max-width: 767px) {
    padding: 53px 20px 80px;
    .user-terms-con {
      h2 {
        font-size: 13px;
        margin-top: 40px;
        margin-bottom: 22px;
      }
      p, li {
        font-size: 12px;
        margin-bottom: 12px;
      }
    }
    .user-terms-con {
      .terms-wide-table {
        th {
          width: 100px;
        }
      }
    }
  }
  @media all and (min-width: 0px) and (max-width: 479px) {
    .user-terms-con {
      margin-bottom: 80px;

      .terms-wide-table th {
        width: 100px;
      }
    }
  }
}
</style>
